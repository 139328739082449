import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button, Dimmer, Form, Input, List, ListContent, ListIcon, ListItem, Loader, Segment } from "semantic-ui-react";
import Modal from "rsuite/Modal"
import { Toaster } from "../component/Toaster";
import { curencyNoSym } from "../component/utils";
import { useNavigate } from "react-router-dom";

export const SalesEntryPos = () => {
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const navigate = useNavigate()
    const [modalOpen, setModalOpen] = useState(false)
    const [beginCash, setBeginCash] = useState(10000)
    const [showroom, setShowroom] = useState('')
    const [openedPos, setOpenedPos] = useState(null)
    const [lastCash, setLastCash] = useState(0)
    const [modalCloseKasir, setModalCloseKasir] = useState(false)
    const [loader, setLoader] = useState(false)

    const submitOpen = () => {
        const param = new FormData()
        const haveAccess = userProfile.group.some(i => i.id_showroom === showroom)

        if (openedPos?.status === 'open') {
            Toaster.fire({
                icon: 'warning',
                text: 'POS was opened before',
                position: 'top-right'
            })
        } else {
            if (userProfile.profile.isCashier && haveAccess) {

                param.append('id_user', userProfile.profile.id_user)
                param.append('id_showroom', userProfile.group[0].id_showroom)
                param.append('id_warehouse', userProfile.group[0].id_warehouse)
                param.append('begin_cash', beginCash)

                axios({
                    method: "POST",
                    url: `${host}/sales/index.php?action=createOpenPos`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: param
                }).then(res => {
                    if (res.data.status === 200) {
                        Toaster.fire({
                            icon: 'success',
                            text: 'Success open pos',
                            position: 'top-right'
                        })
                        getOpenedPos(userProfile.group[0].id_showroom)
                        setModalOpen(false)
                    } else {
                        Toaster.fire({
                            icon: 'error',
                            text: res.data.msg,
                            position: 'top-right'
                        })
                    }
                })
            } else {
                Toaster.fire({
                    icon: 'warning',
                    text: 'You have no access to this showroom',
                    position: 'top-right'
                })
            }
        }
    }

    const getOpenedPos = (showroom) => {
        setLoader(true)
        axios({
            method: "GET",
            url: `${host}/sales/index.php?action=openPosShowroom&id_showroom=${showroom}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        }).then(res => {
            if (res.data.status === 200) {
                setOpenedPos(res.data.data[0])
                setLoader(false)
            }
        })
    }

    const handleOpenPos = () => {
        setModalOpen(true)
    }

    const handleClosePos = () => {
        if (openedPos.id_user === userProfile.profile.id_user) {
            setModalCloseKasir(true)
        } else {
            Toaster.fire({
                icon: "error",
                title: "You cannot close this opened POS",
                text: "must be closed by the person who opened it",
                position: "top-right",
            })
        }
    }

    const submitCloseCashier = () => {
        const msg = window.confirm("are you sure?")
        if (msg) {
            const param = {
                id_open: openedPos?.id_open,
                last_cash: lastCash
            }
            axios({
                method: "PUT",
                url: `${host}/sales/index.php?action=closePos`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success close POS',
                        position: 'top-right',
                    })
                    getOpenedPos(userProfile.group[0].id_showroom)
                    setModalCloseKasir(false)
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: 'top-right',
                    })
                }
            })
        }

    }

    const handleClickPos = () => {
        if (openedPos) {
            navigate('/sales/pos_entry', { state: openedPos })
        } else {
            alert("POS not yet Open")
        }
    }

    const handleClickPosTrx = () => {
        if (openedPos) {
            navigate("/sales/pos_trx_data", { state: openedPos })
        } else {
            alert("POS not yet Open")
        }
    }

    const handleClickTrxReturn = () => {
        if (openedPos) {
            navigate("/sales/pos_retur", { state: openedPos })
        } else {
            alert("POS not yet Open")
        }
    }

    useEffect(() => {
        const idShowroom = userProfile?.group[0]?.id_showroom
        getOpenedPos(idShowroom)
    }, [])
   
    return (
        <>
            <Container >
                <div className="mb-4 mt-2">
                    <div className="h4">POS Information</div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                    <Dimmer active={loader} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Col xl={8} lg={10} md={10} sm={12} className="mb-5">
                        <div style={{ border: '1px solid lightgrey', borderRadius: '5px', padding: '1.5em' }}>
                            {openedPos &&
                                <>
                                    <div className="fs-5 fw-bold">Opened POS Detail</div>
                                    <List relaxed animated verticalAlign="middle">
                                        <ListItem>
                                            <ListIcon name="check" />
                                            <ListContent className="text-capitalize">{openedPos?.status}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="time" />
                                            <ListContent className="text-capitalize">{openedPos?.open_time}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="key" />
                                            <ListContent>{openedPos?.id_open}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="home" />
                                            <ListContent>{openedPos?.showroom_name}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="map marker alternate" />
                                            <ListContent>{openedPos?.address}, {openedPos?.city}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="user secret" />
                                            <ListContent>{openedPos?.supervisor}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="user" />
                                            <ListContent>{openedPos?.user_name} - {openedPos?.role_name}</ListContent>
                                        </ListItem>
                                        <ListItem>
                                            <ListIcon name="money" />
                                            <ListContent>{curencyNoSym.format(openedPos?.begin_cash)}</ListContent>
                                        </ListItem>
                                    </List>
                                </>
                            }
                            <Button fluid size="large" color="orange" content="Open POS" onClick={handleOpenPos} icon="sign-in" labelPosition="right" style={{ display: openedPos?.status === 'open' ? 'none' : 'block' }} />
                            <Button fluid size="large" color="black" content="Close POS" onClick={handleClosePos} icon="sign-out" labelPosition="right" style={{ display: openedPos?.status === 'open' ? 'block' : 'none' }} />
                        </div>
                    </Col>
                    <Col xl={8} lg={10} md={10} sm={12}>
                        <Row className="mb-3">
                            <Col lg={4}>
                                <Button
                                    fluid
                                    content="POS Sales Entry"
                                    icon="cart"
                                    labelPosition="left"
                                    color="blue"
                                    disabled={openedPos ? false : true}
                                    onClick={handleClickPos}
                                />
                            </Col>
                            <Col lg={4}>
                                <Button
                                    fluid
                                    content="POS Transaction List"
                                    icon="list"
                                    labelPosition="left"
                                    color="blue"
                                    disabled={openedPos ? false : true}
                                    onClick={handleClickPosTrx}
                                />
                            </Col>
                            <Col lg={4}>
                                <Button
                                    fluid
                                    content="POS Transaction Return"
                                    icon="retweet"
                                    labelPosition="left"
                                    color="blue"
                                    disabled={openedPos ? false : true}
                                    onClick={handleClickTrxReturn}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container >

            <Modal open={modalOpen} onClose={() => setModalOpen(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Open Pos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmOpen" onSubmit={submitOpen}>
                        <Form.Dropdown
                            options={showroomOpt}
                            value={showroom}
                            selection
                            label="Showroom"
                            onChange={(e, { value }) => setShowroom(value)}
                        />
                        <Form.Input type="number" label="Begin Cash" value={beginCash} labelPosition="left" icon="money outline" onChange={(e) => setBeginCash(e.target.value)} required />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" type="submit" form="frmOpen" icon="save outline" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalCloseKasir} onClose={() => setModalCloseKasir(false)} overflow={false} backdrop="static" size="xs">
                <Modal.Header>
                    <Modal.Title>Last Cash</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="frmLastCash" onSubmit={submitCloseCashier}>
                        <Form.Input type="number" value={lastCash} label="Input Last Cash" onChange={(e) => setLastCash(e.target.value)} required fluid min={50000} />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit and Close POS" form="frmLastCash" type="submit" />
                </Modal.Footer>
            </Modal>
        </>
    )
}