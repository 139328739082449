
import './App.css';
import { SidebarComponent } from './component/SidebarComponent';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Dashboard } from './page/Dashboard';
import { MasterArticle } from './page/MasterArticle';
import { GlobalData, useMyGlobal } from './component/Global';
import { Login } from './page/Login';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { MasterVendor } from './page/MasterVendor';
import { PurchaseOrder } from './page/PurchaseOrder';
import { Warehouse } from './page/Warehouse';
import { Showroom } from './page/Showroom';
import { GoodsReceipt } from './page/GoodsReceipt';
import { ProductStock } from './page/ProductStock';
import { StockTransfer } from './page/StockTransfer';
import { StockAdjustment } from './page/StockAdjustment';
import { StockMovement } from './page/StockMovement';
import { User } from './page/User';
import { ProductDiscount } from './page/ProductDiscount';
import { SalesEntryPos } from './page/SalesEntryPos';
import { SalesAgent } from './page/SalesAgent';
import { MasterEdc } from './page/MasterEdc';
import { MasterCreditCard } from './page/MasterCreditCard';
import { Pos } from './page/Pos';
import { Role } from './page/Role';
import { Voucher } from './page/Voucher';
import { Brand } from './page/Brand';
import { PosTransactionData } from './page/PosTransactionData';
import { PosRetur } from './page/PosRetur';
import { Announcement } from './component/Announcement';
import { Announc } from './page/Announc';
import { ReportSales } from './page/ReportSales';
import { Membership } from './page/Membership';
import { LockerRent } from './page/LockerRent';
import { PaymentInvoice } from './page/PaymentInvoice';
import { Payment } from './page/Payment';
import { MasterBank } from './page/MasterBank';
import { StockOpnameEntry } from './page/StockOpnameEntry';
import { StockOpnameRelease } from './page/StockOpnameRelease';
import { ICTExpenseEntry } from './page/ICTExpenseEntry';
import { InventoryPC } from './page/InventoryPC';
import { FeeCashBank } from './page/FeeCashBank';
import { SaleThrough } from './page/SaleThrough';


function App() {
  const [logData, setLogData] = useState(null)
  const [collapsMenu, setCollapsMenu] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Set collapsed menjadi true saat URL berubah ke rute yang ditentukan
    if (location.pathname === '/sales/pos_entry') {
      setCollapsMenu(true);
    } else {
      setCollapsMenu(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setLogData(typeof Cookies.get('_erpLog') === 'undefined' ? null : Cookies.get('_erpLog'))
  }, [])

  return (
    <div className="App" style={{ display: 'flex', marginBottom: 'auto' }}>
      <GlobalData>
        {
          logData ?
            <div
              style={{ display: 'flex', width: '100%', direction: 'ltr' }}
            >
              <aside>
                <SidebarComponent collaps={collapsMenu} />
              </aside>
              <main className='p-0 w-100 vh-100' style={{ overflowY: 'auto', marginBottom: 'auto' }}>
                <Routes>
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '1') &&
                    <Route exact path='/' element={<Dashboard />} />
                  }
                  <Route path='/Login' element={<Login />} />
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '2') &&
                    <>
                      <Route path='/product/master_article' element={<MasterArticle />} />
                      <Route path='/product/discount' element={<ProductDiscount />} />
                      <Route path='/product/brand' element={<Brand />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '3') &&
                    <>
                      <Route path='/purchasing/master_vendor' element={<MasterVendor />} />
                      <Route path='/purchasing/purchase_order' element={<PurchaseOrder />} />
                      <Route path='/purchasing/goods_receipt' element={<GoodsReceipt />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '4') &&
                    <>
                      <Route path='/showroom/warehouse' element={<Warehouse />} />
                      <Route path='/showroom/showroom' element={<Showroom />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '5') &&
                    <>
                      <Route path='/stock/product_stock' element={<ProductStock />} />
                      <Route path='/stock/stock_transfer' element={<StockTransfer />} />
                      <Route path='/stock/stock_adjustment' element={<StockAdjustment />} />
                      <Route path='/stock/stock_movement' element={<StockMovement />} />
                      <Route path='/stock/stock_opname_entry' element={<StockOpnameEntry />} />
                      <Route path='/stock/stock_opname_release' element={<StockOpnameRelease />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '7') &&
                    <>
                      <Route path='/administrator/user' element={<User />} />
                      <Route path='/administrator/role' element={<Role />} />
                      <Route path='/administrator/announcement' element={<Announc />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '8') &&
                    <>
                      <Route path='/report/sales' element={<ReportSales />} />
                      <Route path='/report/sale_through' element={<SaleThrough />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '9') &&
                    <>
                      <Route path='/loyalty_program/membership' element={<Membership />} />
                      <Route path='/loyalty_program/lockerPage' element={<LockerRent />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '12') &&
                    <>
                      <Route path='/ict/ict_expense_entry' element={<ICTExpenseEntry />} />
                      <Route path='/ict/inventory_pc' element={<InventoryPC />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '6') &&
                    <>
                      {
                        JSON.parse(logData)?.profile?.isCashier === '1' ? // KASIR
                          <Route path='/sales/pos' element={<SalesEntryPos />} />
                          : <></>
                      }
                      <Route path='/sales/agent' element={<SalesAgent />} />
                      <Route path='/sales/master_edc' element={<MasterEdc />} />
                      <Route path='/sales/master_cc' element={<MasterCreditCard />} />
                      <Route path='/sales/pos_entry' element={<Pos />} />
                      <Route path='/sales/voucher' element={<Voucher />} />
                      <Route path='/sales/pos_retur' element={<PosRetur />} />
                      <Route path='/sales/pos_trx_data' element={<PosTransactionData />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '10') &&
                    <>
                      <Route path='payment/payable_invoice' element={<PaymentInvoice />} />
                      <Route path='payment/payable_invoice_payment' element={<Payment />} />
                      <Route path='payment/master_bank' element={<MasterBank />} />
                    </>
                  }
                  {
                    JSON.parse(logData)?.access?.some(page => page.id_page === '13') &&
                    <>
                      <Route path='finance/fee_cash_bank' element={<FeeCashBank />} />
                    </>
                  }
                </Routes>
                <Announcement />
              </main>
            </div>
            : <Login />
        }
      </GlobalData>
    </div>

  );
}

export default App;
