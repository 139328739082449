import React, { useState } from "react";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { Button } from "semantic-ui-react";
import Modal from "rsuite/modal"

export const FeeCashBank = () => {
    const [modalCreate, setModalCreate] = useState(false)

    const handleClickAdd = () => {

    }
    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Fee Cash Bank</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={10} lg={12} md={12} className="mb-3">
                        <Button content="Create New Fee" icon="add" onClick={handleClickAdd} color="blue" />
                    </Col>
                    <Col xl={10} lg={12} md={12}>
                        
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} >
                <Modal.Header>
                    <Modal.Title>Create New Fee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xl={12} lg={12} md={12}>
                            
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}