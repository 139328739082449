import React, { useEffect, useMemo, useState } from "react";
import { Reacttable } from "../component/Reacttable";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import { curencyNoSym } from "../component/utils";
import { Button, Popup, Icon, Form, Dropdown, Label, Dimmer, Loader, Segment } from "semantic-ui-react";
import moment from "moment";
import Modal from "rsuite/Modal";
import Timeline from "rsuite/Timeline"

export const ProductStock = () => {
    const { host } = useMyGlobal()
    const [dataStock, setDataStock] = useState([])
    const [modalTimeline, setModalTimeline] = useState(false)
    const [dataTimeline, setDataTimeline] = useState(null)
    const [warehouseOpt, setWarehouseOpt] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [filterTerm, setFilterTerm] = useState([])
    const [totalArt, setTotalArt] = useState(0)
    const [totalBrand, setTotalBrand] = useState(0)
    const [totalQty, setTotalQty] = useState(0)
    const [emptyStock, setEmptyStock] = useState(0)
    const [totalWarehouse, setTotalWarehouse] = useState(0)
    const [exportType, setExportType] = useState('excel')
    const [brandCodeOpt, setBrandCodeOpt] = useState('')
    const [brandCode, setBrandCode] = useState('')
    const [loadMove, setloadMove] = useState(false)
    const [dateRange, setDateRange] = useState([
        // new Date(moment().startOf('month')),
        new Date(moment().subtract(3, 'months').startOf('month')), // 3 bulan kebelakang
        new Date(moment()),
    ])

    const columns = useMemo(() => [
        {
            Header: "Warehouse",
            accessor: "warehouse_name",
        },
        {
            Header: "Brand",
            accessor: "brand_code",
        },
        {
            Header: "Season",
            accessor: "season",
        },
        {
            Header: "Article",
            accessor: "article",
        },
        {
            Header: "Description",
            accessor: "description",
            Cell:({cell}) => (
                <div style={{width:'250px'}}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Cogs",
            accessor: "cogs",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Qty",
            accessor: "qty"
        },
        {
            Header: "Last Update",
            accessor: "updateAt"
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Stock Movement" size="tiny" trigger={
                        <Button icon={{ name: 'history', color: 'blue' }} onClick={() => handleClickTimeline(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const handleClickTimeline = (data) => {
        getStockMoveTimeline(data)
        setModalTimeline(true)
    }

    const getStockMoveTimeline = (data) => {
        setloadMove(true)
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]
        const article = data.article
        const size = data.size
        const warehouse = data.id_warehouse

        axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockMovementTimeline`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                date_range,
                article,
                size,
                warehouse
            },
        }).then(res => {
            if (res.data.status === 200) {
                setDataTimeline(res.data.data)
            }
            setloadMove(false)
        })
    }


    const getStock = async () => {
        await axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stock`,
            headers: {
                "Content-Type": "application/json",
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataStock(res.data.data)
                setFilteredData(res.data.data)
                const uniqueWarehouses = {}
                res.data.data.forEach(item => {
                    if (!uniqueWarehouses[item.id_warehouse]) {
                        uniqueWarehouses[item.id_warehouse] = {
                            key: item.id_warehouse,
                            text: item.warehouse_name,
                            value: item.id_warehouse,
                        }
                    }
                });

                setWarehouseOpt(Object.values(uniqueWarehouses))
                const totalQty = res.data.data.reduce((total, item) => {
                    const qty = parseInt(item.qty, 10)
                    return total + (isNaN(qty) ? 0 : qty)
                }, 0)
                const totalArt = new Set(res.data.data.map(product => product.article)).size
                const totalBrand = new Set(res.data.data.map(product => product.brand_code)).size
                const emptyStock = res.data.data.filter(product => product.qty == 0).length
                const totalWh = new Set(res.data.data.map(product => product.id_warehouse)).size
                setTotalQty(totalQty)
                setTotalArt(totalArt)
                setTotalBrand(totalBrand)
                setEmptyStock(emptyStock)
                setTotalWarehouse(totalWh)
            }
        })
    }

    const handleSelectWH = (warehouse) => {
        setFilterTerm(warehouse)
    }

    const handleSubmitFilter = (wh) => { 
        const filtered = dataStock.filter(item => wh.includes(item.id_warehouse))
        setFilteredData(filtered)
        setFilterTerm(wh)
    }

    const handleClickShowAll = () => {
        setFilterTerm('')
        getStock()
    }

    const exportTo3dFoot = (wh) => {
        const param = new FormData()
        param.append('id_warehouse', wh)
        axios({
            method: "POST",
            url: `${host}/stock/index.php?action=export3dFoot`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'stock_3dfoot.csv');
            document.body.appendChild(link)
            link.click()
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const exportToExcel = () => {
        const param = new FormData()
        param.append('id_warehouse', JSON.stringify(filterTerm))
        // param.append('brand_code', brandCode)
        axios({
            method: "POST",
            url: `${host}/stock/index.php?action=exportStockToExcel`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `Stock per ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`);
            document.body.appendChild(link)
            link.click()
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const handleExport = () => {
        if (exportType === '3dfoot') {
            exportTo3dFoot(filterTerm)
        } else if (exportType === 'excel') {
            exportToExcel()
        }

    }

    useEffect(() => {
        getStock()
    }, [])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Product Stock</div>
                </div>
                <Row className="d-flex justify-content-start">
                    <Col lg={8} className="mb-3">
                        <Dropdown
                            className="me-3"
                            labeled
                            floating
                            multiple
                            selection
                            value={filterTerm}
                            options={warehouseOpt}
                            search
                            placeholder='Filter Warehouse'
                            onChange={(e, { value }) => handleSubmitFilter(value)}
                        />
                        {/* <Button content="Filter" icon="filter" onClick={handleSubmitFilter} color="blue" className="me-3" disabled={filterTerm ? false : true} /> */}
                        <Button content="Reset" color="blue" basic onClick={handleClickShowAll} icon="undo" labelPosition="left" disabled={filterTerm === '' ? true : false} />
                    </Col>
                    <Col lg={4} className="text-end">
                        <Button.Group>
                            <Dropdown
                                labeled
                                className='button icon'
                                floating
                                options={[
                                    { key: 1, value: 'excel', text: 'Excel', icon: { name: 'file excel', color: 'green' } },
                                    { key: 2, value: '3dfoot', text: '3DFoot', icon: { name: 'file', color: 'green' } },
                                ]}
                                trigger={<span className="text-capitalize">{exportType}</span>}
                                onChange={(e, { value }) => setExportType(value)}
                            />
                            <Button onClick={handleExport} icon="share" content="Export" labelPosition="right" color="orange" />
                        </Button.Group>
                    </Col>
                    <Col lg={12}>
                        <Reacttable data={filteredData} columns={columns} pageRow={8} noData={filteredData.length === 0 ? true : false} />
                    </Col>
                    <Col lg={12}>
                        <Label content={totalQty + " Quantity"} icon="box" color="blue" circular basic className="me-2" />
                        <Label content={totalArt + " Article's"} icon="sticky note" color="blue" circular basic className="me-2" />
                        <Label content={totalBrand + " Brand's"} icon="registered" color="blue" circular basic className="me-2" />
                        <Label content={emptyStock + " Out of Stock"} icon="flag" color="blue" circular basic className="me-2" />
                        <Label content={totalWarehouse + " Warehouse"} icon="warehouse" color="blue" circular basic className="me-2" />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalTimeline} onClose={() => setModalTimeline(false)} overflow={true} size='xs'>
                <Modal.Header>
                    <Modal.Title>Stock Movement Timeline</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <Dimmer active={loadMove} inverted>
                        <Loader content='Loading...' />
                    </Dimmer>
                    <Timeline isItemActive={Timeline.ACTIVE_FIRST} endless>
                        {
                            dataTimeline?.length > 0 ?
                                dataTimeline.map((item, i) => (
                                    <Timeline.Item key={i}>
                                        <div className="fw-bold">
                                            <span className="me-2">{item.move_time}</span>
                                            <span><Icon name={item.move_type === 'IN' ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} color={item.move_type === 'IN' ? 'blue' : 'pink'} />{item.move_type}</span>
                                        </div>
                                        <div>
                                            <span className="me-3">{item.event}</span>
                                            <span>Qty : <span className="fw-bold">{item.qty}</span> &nbsp; Qty Before : {item.qty_before}</span>
                                        </div>
                                        <div className="text-muted">
                                            <span>User : </span>
                                            <span className="me-2">{item.user_name}</span>
                                            <span>({item.role_name})</span>
                                        </div>
                                    </Timeline.Item>
                                ))
                                :
                                <div className="text-center">
                                    <div><Icon name="info circle" color="orange" size="large" /></div>
                                    <div>No data found</div>
                                </div>
                        }
                    </Timeline>
                </Modal.Body>
            </Modal>

        </>
    )
}