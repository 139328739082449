import React, { useEffect, useMemo, useRef, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Stack from "react-bootstrap/Stack"
import { Button, Checkbox, Dimmer, Form, Icon, Input, Loader, Popup, Transition } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Modal from "rsuite/Modal"
import { Reacttable } from "../component/Reacttable";
import Table from "react-bootstrap/Table"
import { Toaster } from "../component/Toaster";
import { curencyNoSym } from "../component/utils";
import moment from "moment";
import { Badge, Timeline } from "rsuite";


export const StockOpnameEntry = () => {
    const { userProfile, host, warehouseOpt, brandOpt, playSound } = useMyGlobal()
    const [modalCreate, setModalCreate] = useState(false)
    const [brand, setBrand] = useState('')
    const [warehouse, setWarehouse] = useState('')
    const [soNumber, setSoNumber] = useState('')
    const [desc, setDesc] = useState('')
    const [dataStock, setDataStock] = useState([])
    const [article, setArticle] = useState('')
    const [modalArtDetail, setModalArtDetail] = useState(false)
    const [articleDetail, setArticleDetail] = useState([])
    const inputRefs = useRef([]);
    const [selectedSize, setSelectedSize] = useState([])
    const [totalQty, setTotalQty] = useState(0)
    const [scanValue, setScanValue] = useState('')
    const [visible, setVisible] = useState(true)
    const [soDate, setSoDate] = useState('')
    const [soEntryData, setSoEntryData] = useState([])
    const [modalDetail, setModalDetail] = useState(false)
    const [selectedRowSo, setSelectedRowSo] = useState(null)
    const [modalEdit, setModalEdit] = useState(false)
    const [loadEdit, setLoadEdit] = useState(false)
    const [loadValidate, setLoadValidate] = useState(null)
    const [stockTimeline, setStockTimeline] = useState([])
    const [modalTimeline, setModalTimeline] = useState(false)
    const [loadTimeline, setLoadTimeline] = useState(false)
    const [dimActive, setDimActive] = useState(false)
    const inputRef = useRef(null);

    const columnsSo = useMemo(() => [
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ cell }) => (
                <div style={{ width: '100px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>} />
                </div>
            )
        },
        {
            Header: 'Price',
            accessor: 'price',
            Cell: ({ cell }) => <div>{curencyNoSym.format(cell.value)}</div>
        },
        {
            Header: 'Qty',
            accessor: 'qty'
        },
        {
            Header: 'SO',
            accessor: 'so_qty',
            Cell: ({ cell }) => (
                <input
                    min="0"
                    type="number"
                    value={cell.value}
                    onChange={(e) => handleSave(e.target.value, cell.row.original)}
                    style={{ border: 'none', width: '40px', padding: '3px' }}
                />
            )
        },
        {
            Header: 'Diff',
            accessor: 'difference',
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (
                cell.value === 'Difference' ?
                    <span><Icon name="exclamation circle" color="orange" />{cell.value}</span>
                    :
                    cell.value === 'Balance' ?
                        <span><Icon name="check circle" color="blue" />{cell.value}</span> : <></>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <div className="text-center">
                    <Icon name="close" color="orange" style={{ cursor: 'pointer' }} onClick={() => handleRemoveItem(cell.row.original)} />
                </div>
            )
        }
    ])

    const columnsDataEntry = useMemo(() => [
        {
            Header: 'SO Number',
            accessor: 'header.so_number'
        },
        {
            Header: 'SO Date',
            accessor: 'header.so_date'
        },
        {
            Header: 'Warehouse',
            accessor: 'header.wh_desc'
        },
        {
            Header: 'Brand',
            accessor: 'header.brand',
            Cell: ({ cell }) => (
                cell.value === null ? <span className="text-muted">All</span> : cell.value
            )
        },
        {
            Header: 'Description',
            accessor: 'header.description',
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <div className="text-truncate">{cell.value}</div>
                </div>
            )
        },
        {
            Header: 'SO Qty',
            accessor: 'header.total_qty'
        },
        {
            Header: 'Validation Time',
            accessor: 'header.validation_time',
            Cell: ({ cell }) => (
                cell.value === null ? <span className="text-muted">N/A</span> : cell.value
            )
        },
        {
            Header: 'Qty Loss',
            accessor: 'header.total_loss',
        },
        {
            Header: 'Variance',
            accessor: 'header.is_variance',
            Cell: ({ cell }) => (
                cell.value === 'Y' ?
                    <>
                        <span className="me-2"><Badge color="red" /></span>
                        <span className="fw-bold">Variance</span>
                    </>
                    :
                    cell.value === 'N' ?
                        <>
                            <span className="me-2"><Badge color="blue" /></span>
                            <span className="fw-bold">Valid</span>
                        </>
                        :
                        <>
                            <span className="me-2"><Badge color="yellow" /></span>
                            <span className="text-muted">Unvalidated</span>
                        </>
            )
        },
        {
            Header: 'Validate',
            accessor: 'header.validate',
            Cell: ({ cell }) => (
                <>
                    {
                        cell.value === 'Y' ?
                            <>
                                <Popup content="This SO has been validated" size="tiny" trigger={
                                    <span><Icon name="check circle outline" color="blue" size="large" /></span>} />
                            </>
                            :
                            <>
                                <Popup content="This SO not yet validated" size="tiny" trigger={
                                    <span><Icon name="times circle outline" color="grey" size="large" /></span>} />
                            </>
                    }
                </>
            )
        },
        {
            Header: 'Create By',
            accessor: 'header.user_name',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1em' }}>
                    <div >{cell.value}</div>
                    <div className="text-muted text-capitalize">{cell.row.original.header.role_name}</div>
                </Stack>
            )
        },
        {
            Header: 'Edited By',
            accessor: 'header.user_name_edit',
            Cell: ({ cell }) => (
                <Stack direction="vertical" style={{ lineHeight: '1em' }}>
                    <div >{cell.value}</div>
                    <div className="text-muted text-capitalize">{cell.row.original.header.role_name_edit}</div>
                </Stack>
            )
        },
        {
            Header: 'Action',
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group basic size="mini">
                    <Popup content="Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                    {
                        cell.row.original.header.validate === 'N' ?
                            <Popup content="Edit SO Entry" size="tiny" trigger={
                                <Button icon={{ name: "edit", color: 'blue' }} onClick={() => handleClickEdit(cell.row.original)} />
                            } />
                            : <></>
                    }
                    {cell.row.original.header.is_release === 'N' ?
                    <Popup content="Validate SO Quantity" size="tiny" trigger={
                        <Button icon={{ name: 'sync', color: 'blue' }} onClick={() => handleClickValidate(cell.row.original)} loading={cell.row.original.so_number === loadValidate?.id ? loadValidate?.status : false} disabled={cell.row.original.so_number === loadValidate?.id ? loadValidate?.status : false} />
                    } />
                    :<></>
                    }
                </Button.Group>
            )
        },
    ])

    const columnsDataEntryDet = useMemo(() => [
        {
            Header: 'Article',
            accessor: 'article'
        },
        {
            Header: 'Barcode',
            accessor: 'barcode'
        },
        {
            Header: 'Size',
            accessor: 'size'
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: 'Qty',
            accessor: 'qty'
        },
        {
            Header: 'SO Qty',
            accessor: 'so_qty'
        },
        {
            Header: 'Qty Loss',
            accessor: 'qty_loss',
            Cell: ({ cell }) => (
                <div>
                    <Popup content="View Stock Movement for this item" size="tiny" trigger={
                        <span style={{ cursor: 'pointer' }}><Icon name="history" color="blue" onClick={() => handleClickStockTimeline(cell.row.original)} /></span>}
                    />
                    <span>{cell.value}</span>
                </div>
            )
        },
        {
            Header: 'SO Matching',
            accessor: 'so_matching'
        },
        {
            Header: 'Diff',
            accessor: 'difference'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ cell }) => (
                <div>
                    {
                        cell.value === "Difference" ?
                            <>
                                <span><Icon name="exclamation circle" color="orange" /></span>
                                <span>{cell.value}</span>
                            </>
                            :
                            <>
                                <span><Icon name="check circle" color="blue" /></span>
                                <span>{cell.value}</span>
                            </>
                    }
                </div>
            )
        },
    ])

    const handleClickStockTimeline = (data) => {
        const dateRange = [
            selectedRowSo.header.createAt,
            new Date(moment())
        ]
        getStockTimeline(dateRange, data.article, data.size, selectedRowSo.header.id_warehouse)
        setModalTimeline(true)
    }

    const getStockTimeline = async (dateRange, art, sz, wh) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]
        const article = art
        const size = sz
        const warehouse = wh
        setLoadTimeline(true)
        axios({
            method: "GET",
            url: `${host}/stock/index.php?action=stockMovementTimeline`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                date_range,
                article,
                size,
                warehouse
            },
        }).then(res => {
            if (res.data.status === 200) { console.log(res.data.data)
                setStockTimeline(res.data.data)
            }
        }).finally(() => setLoadTimeline(false))
    }

    const handleClickValidate = async (data) => {
        const cfm = window.confirm("Are you sure?\nData that has been validated cannot be edited")

        if (cfm) {
            setLoadValidate({ id: data.header.so_number, status: true })
            const param = new FormData()
            param.append('warehouse', data.header.id_warehouse)
            param.append('so_number', data.header.so_number)

            await axios({
                method: 'POST',
                url: `${host}/stock/index.php?action=checkSoVariant`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: param
            }).then(res => {
                if (res.data.status === 200) {
                    Toaster.fire({
                        icon: 'success',
                        text: 'Success Validate this SO',
                        position: "top-right"
                    })
                    getSoEntry()
                } else {
                    Toaster.fire({
                        icon: 'error',
                        text: res.data.msg,
                        position: "top-right"
                    })
                }
            }).finally(() => setLoadValidate({ id: data.so_number, status: false }))
        }
    }

    const handleClickEdit = (data) => {
        getStok()
        setWarehouse(data.header.id_warehouse)
        setSoDate(data.header.so_date)
        setBrand(data.header.id_brand)
        setDesc(data.header.description)
        setSoNumber(data.header.so_number)

        setSelectedRowSo(data)
        setSelectedSize(data.detail)
        setModalEdit(true)
    }

    const handleClickDetail = (data) => {
        setSelectedRowSo(data)
        setModalDetail(true)
    }

    const handleSave = (newQty, data) => {
        const newData = selectedSize.map(item => (
            item.barcode === data.barcode ?
                {
                    ...item,
                    so_qty: parseInt(newQty),
                    difference: parseInt(item.qty) - parseInt(newQty),
                    status: parseInt(item.qty) !== parseInt(newQty) ? 'Difference' : 'Balance',
                }
                : item
        ))
        setSelectedSize(newData)
    }

    const handleRemoveItem = (data) => {
        const cfm = window.confirm('Are you sure, want to remove this item?')
        if (cfm) {
            const newSelected = selectedSize.filter(item => item.barcode !== data.barcode)
            setSelectedSize(newSelected)
        }
    }

    const handleClickCreateNew = () => {
        generateSoNumber()
        getStok()
        setModalCreate(true)
    }

    const generateSoNumber = async () => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=soNumber`,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setSoNumber(res.data)
        })
    }

    const getStok = async () => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=stock`,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            if (res.data.status === 200) {
                setDataStock(res.data.data)
            }
        })
    }

    const handleExecuteArt = () => {
        playSound('button')

        let filteredStock = []
        if (article !== "") {
            if (brand !== '') {
                filteredStock = dataStock.filter(item => item.id_warehouse === warehouse && item.brand_code === brand && item.article === article)
                    .map((i, x) => ({
                        ...i, checked: false, so_qty: 0
                    })).filter(item => !selectedSize.some(sel => sel.barcode === item.barcode))
                setArticleDetail(filteredStock)
                setModalArtDetail(true)
            } else {
                filteredStock = dataStock.filter(item => item.id_warehouse === warehouse && item.article === article).map((i, x) => ({
                    ...i, checked: false, so_qty: 0
                })).filter(item => !selectedSize.some(sel => sel.barcode === item.barcode))
                setArticleDetail(filteredStock)
                setModalArtDetail(true)
            }
        } else {
            alert('No Article Code found...\nplease input article code first')
        }
    }

    const handleChangeCheck = (item, checked) => {
        if (!checked) {
            const updateData = articleDetail.map(i => {
                if (i.barcode === item.barcode) {
                    return {
                        ...i,
                        checked: !checked,
                    }
                }
                return i
            })

            setArticleDetail(updateData)
        } else {
            const updateData = articleDetail.map(i => {
                if (i.barcode === item.barcode) {
                    return {
                        ...i,
                        checked: !checked,
                        so_qty: 0,
                        difference: '',
                        status: ''
                    }
                }
                return i
            })

            setArticleDetail(updateData)
        }
    }

    const handleChangeSoQty = (item, qty) => {
        const updateData = articleDetail.map(i => {
            if (i.barcode === item.barcode) {
                return {
                    ...i,
                    so_qty: parseInt(qty),
                    difference: item.qty - qty,
                    status: item.qty != qty ? 'Difference' : item.qty === qty ? 'Balance' : ''
                }
            }
            return i
        })
        setArticleDetail(updateData)
    }

    const submitSelectedSize = () => {
        const selected = articleDetail.filter(i => i.checked)
        setSelectedSize(prev => [...prev, ...selected].reverse())
        setModalArtDetail(false)
    }

    const handleChangeScanValue = () => {
        let selectedData = []
        if (scanValue !== '') {
            if (brand !== '') {
                const filteredStock = dataStock.filter(item => item.id_warehouse === warehouse && item.brand_code === brand)
                    .map((i, x) => ({
                        ...i,
                        checked: true,
                        so_qty: (typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1,
                        difference: parseInt(i.qty) - parseInt((typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1),
                        status: parseInt(i.qty) !== parseInt((typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1) ? 'Difference' : 'Balance'
                    }
                    ))
                selectedData = filteredStock.find(item => item.barcode === scanValue)
            } else if (brand === '') {
                const filteredStock = dataStock.filter(item => item.id_warehouse === warehouse)
                    .map((i, x) => ({
                        ...i,
                        checked: true,
                        so_qty: (typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1,
                        difference: parseInt(i.qty) - parseInt((typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1),
                        status: parseInt(i.qty) !== parseInt((typeof i.so_qty === 'number' && !isNaN(i.so_qty)) ? i.so_qty + 1 : 1) ? 'Difference' : 'Balance'
                    }
                    ))
                selectedData = filteredStock.find(item => item.barcode === scanValue)
            }

            if (selectedData) {
                setSelectedSize(prev => {
                    const existingItem = prev.find(item => item.barcode === selectedData.barcode);

                    if (existingItem) {
                        // Jika barcode ada, update so_qty dengan menambahkannya
                        return prev.map(item =>
                            item.barcode === selectedData.barcode
                                ? {
                                    ...item,
                                    so_qty: parseInt(item.so_qty) + 1,
                                    difference: parseInt(item.qty) - (parseInt(item.so_qty) + 1),
                                    status: parseInt(item.qty) !== parseInt(item.qty) - (parseInt(item.so_qty) + 1) ? 'Difference' : 'Balance',
                                }
                                : item // Tidak ada perubahan untuk item lain
                        );
                    } else {
                        // Jika barcode tidak ada, tambahkan item baru ke selectedSize
                        return [selectedData, ...prev];
                    }
                })
                setScanValue('')
            } else {
                playSound('alert')
                Toaster.fire({
                    icon: 'warning',
                    title: 'Data not found in database',
                    text:'If article & size not found, then note the information about this item, so you can continue',
                    position: 'center',
                    backdrop: false,
                    allowOutsideClick: false,
                    showCancelButton: true,
                    cancelButtonText: 'Oke, I Understand',
                    cancelButtonColor:'orange',
                    timer: false,
                    toast:false,
                    didOpen:() => {
                        setDimActive(true)
                    },
                    didClose:() => {
                        setDimActive(false)
                        if (inputRef.current) {
                            inputRef.current.focus();
                        }
                    }
                })
                setScanValue('')
            }
        }
    }

    const clearForm = () => {
        setSoNumber("")
        setSoDate("")
        setDesc("")
        setWarehouse("")
        setBrand("")
        setTotalQty(0)
        setSelectedSize([])
    }

    const handleSubmit = async () => {
        const header = {
            so_number: soNumber,
            so_date: soDate,
            desc: desc,
            id_warehouse: warehouse,
            brand: brand,
            total_so_qty: totalQty,
            id_user: userProfile.profile.id_user,
        }

        await axios({
            method: 'POST',
            url: `${host}/stock/index.php?action=createSoEntry`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                header: header,
                detail: selectedSize,
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success create SO Entry',
                    position: "top-right"
                })
                clearForm()
                setModalCreate(false)
                getSoEntry()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: "top-right"
                })
            }
        })
    }

    const getSoEntry = async () => {
        await axios({
            method: 'GET',
            url: `${host}/stock/index.php?action=soEntry`,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSoEntryData(res.data.data)
            }
        })
    }

    const handleSubmitEdit = () => {
        setLoadEdit(true)
        const header = {
            so_number: soNumber,
            so_date: soDate,
            desc: desc,
            id_warehouse: warehouse,
            brand: brand,
            total_so_qty: totalQty,
            id_user: userProfile.profile.id_user,
        }

        axios({
            method: 'PUT',
            url: `${host}/stock/index.php`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                header: header,
                detail: selectedSize,
                action: 'editSoEntry'
            }
        }).then(res => {
            if (res.data.status === 200) {
                Toaster.fire({
                    icon: 'success',
                    text: 'Success update data',
                    position: 'top-right'
                })
                clearForm()
                getSoEntry()
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => {
            setLoadEdit(false)
            setModalEdit(false)
        })
    }

    const handleCloseModalEdit = () => {
        clearForm()
        setModalEdit(false)
    }

    useEffect(() => {
        // Fokus pada input yang checked
        articleDetail.forEach((item, index) => {
            if (item.checked && inputRefs.current[index]) {
                inputRefs.current[index].focus(); // Fokuskan pada input yang sesuai
            }
        })
    }, [articleDetail])

    useEffect(() => {
        setVisible(false)
        setTimeout(() => setVisible(true), 100)
    }, [totalQty])

    useEffect(() => {
        const totalQty = selectedSize.reduce((total, cur) => total + parseInt(cur.so_qty), 0)
        setTotalQty(totalQty)
    }, [selectedSize])

    useEffect(() => {
        getSoEntry()
    }, []
    )

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">Stock Opname Entry</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                        <Button content="Create Stock Opname" icon="add" onClick={handleClickCreateNew} color="blue" />
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Reacttable
                            data={soEntryData}
                            columns={columnsDataEntry}
                            pageRow={10}
                        />
                    </Col>
                </Row>
            </Container>

            <Modal open={modalCreate} onClose={() => setModalCreate(false)} backdrop="static" overflow={false} size="lg">
                <Dimmer active={dimActive} />

                <Modal.Header>
                    <Modal.Title>Create New Opname</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} sm={6} className="mb-2">
                            <Form id="frmCreate" onSubmit={handleSubmit}>
                                <Form.Group>
                                    <Form.Select
                                        options={warehouseOpt}
                                        selection
                                        label="Warehouse"
                                        value={warehouse}
                                        onChange={(e, { value }) => setWarehouse(value)}
                                    />
                                    <Form.Input
                                        type="date"
                                        label="Date"
                                        value={soDate}
                                        onChange={(e) => setSoDate(e.target.value)}
                                    />
                                    <Form.Select
                                        options={brandOpt}
                                        label="Brand"
                                        selection
                                        value={brand}
                                        clearable
                                        placeholder="All Brand"
                                        onChange={(e, { value }) => setBrand(value)}
                                    />
                                    <Form.Input
                                        type="text"
                                        label="Description"
                                        value={desc}
                                        onChange={(e) => setDesc(e.target.value)}
                                    />
                                    <Form.Input
                                        type="text"
                                        readOnly
                                        label="SO Number"
                                        value={soNumber}
                                        width={3}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col lg={12}>
                            <small>Choose one, take in by scanning the Barcode or by Article Code (use article, if there is no barcode)</small>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <Form onSubmit={handleChangeScanValue}>
                                <Form.Field>
                                    <label>Scan Barcode</label>
                                    <Input
                                        ref={inputRef}
                                        fluid={false}
                                        iconPosition="left"
                                        type="text"
                                        value={scanValue}
                                        onChange={(e) => setScanValue(e.target.value)}
                                        placeholder="EAN/UPC Barcode"
                                    >
                                        <Icon name="barcode" />
                                        <input />
                                    </Input>
                                </Form.Field>
                            </Form>
                        </Col>
                        <Col lg={4} sm={6}>
                            <Form>
                                <Form.Field>
                                    <label>Article</label>
                                    <Input
                                        fluid
                                        type="text"
                                        placeholder="Article"
                                        value={article}
                                        onChange={(e) => setArticle(e.target.value)}
                                        action={{ content: 'Lookup', onClick: handleExecuteArt, color: 'blue' }}
                                    />
                                </Form.Field>
                            </Form>
                        </Col>
                        <Col lg={9} sm={12} className="mb-3">
                            <Reacttable
                                columns={columnsSo}
                                data={selectedSize}
                                pageRow={5}
                                noData={selectedSize.length === 0 ? true : false}
                            />
                        </Col>
                        <Col lg={3}>
                            <div className="fw-bold">Total SO Qty :</div>
                            <div
                                style={{
                                    border: '2px solid lightgrey',
                                    borderRadius: '5px',
                                    height: '180px',
                                    width: '100%',
                                    background: 'whitesmoke',
                                    display: 'flex',
                                    justifyContent: 'center',  // Untuk horizontal center
                                    alignItems: 'center'       // Untuk vertical center
                                }}
                            >
                                <Transition visible={visible} animation='jiggle' duration={500}>
                                    <div className="display-1" style={{ fontFamily: 'fantasy', color: 'black' }}>{curencyNoSym.format(totalQty)}</div>
                                </Transition>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Create SO Entry" color="blue" icon="plus" type="submit" form="frmCreate" />
                </Modal.Footer>
            </Modal>

            <Modal open={modalArtDetail} onClose={() => setModalArtDetail(false)} backdrop="static" overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Article Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead style={{ fontSize: '0.9em' }}>
                            <tr>
                                <th></th>
                                <th>#</th>
                                <th>Barcode</th>
                                <th>Size</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>SO.Qty</th>
                                <th>Diff</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ fontSize: '0.8em' }} className="align-middle">
                            {
                                articleDetail.map((i, x) => (
                                    <tr key={x}>
                                        <td>
                                            <Checkbox
                                                checked={i.checked}
                                                onChange={() => handleChangeCheck(i, i.checked)}
                                            />
                                        </td>
                                        <td>{x + 1}</td>
                                        <td>{i.barcode}</td>
                                        <td>{i.size}</td>
                                        <td>{i.description}</td>
                                        <td>{i.qty}</td>
                                        <td>
                                            <Input
                                                type="number"
                                                size="tiny"
                                                ref={(el) => inputRefs.current[x] = el}
                                                style={{ width: '80px', height: '25px' }}
                                                disabled={!i.checked}
                                                value={i.so_qty}
                                                onChange={(e) => handleChangeSoQty(i, e.target.value)} />
                                        </td>
                                        <td>{i.difference}</td>
                                        <td>{i.status === 'Difference' ? <span><Icon name='exclamation circle' color="orange" />Defference</span> : i.status === 'Balance' ? <span><Icon name='check circle' color="blue" />Balance</span> : ''}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Submit" color="blue" icon="arrow alternate circle right" labelPosition="right" onClick={submitSelectedSize} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Detail So Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable
                        data={selectedRowSo?.detail}
                        columns={columnsDataEntryDet}
                        pageRow={10}
                        totals={['qty', 'so_qty', 'qty_loss', 'so_matching']}
                    />
                </Modal.Body>
            </Modal>

            <Modal open={modalEdit} onClose={handleCloseModalEdit} backdrop="static" overflow={false} size="lg">
                <Modal.Header>
                    <Modal.Title>Edit Opname Entry</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} sm={6} className="mb-2">
                            <Form id="frmEdit" onSubmit={handleSubmitEdit}>
                                <Form.Group>
                                    <Form.Select
                                        options={warehouseOpt}
                                        selection
                                        label="Warehouse"
                                        value={warehouse}
                                        onChange={(e, { value }) => setWarehouse(value)}
                                    />
                                    <Form.Input
                                        type="date"
                                        label="Date"
                                        value={soDate}
                                        onChange={(e) => setSoDate(e.target.value)}
                                    />
                                    <Form.Select
                                        options={brandOpt}
                                        label="Brand"
                                        selection
                                        value={brand}
                                        clearable
                                        placeholder="All Brand"
                                        onChange={(e, { value }) => setBrand(value)}
                                    />
                                    <Form.Input
                                        type="text"
                                        label="Description"
                                        value={desc}
                                        onChange={(e) => setDesc(e.target.value)}
                                    />
                                    <Form.Input
                                        type="text"
                                        readOnly
                                        label="SO Number"
                                        value={soNumber}
                                        width={3}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                        <Col lg={12}>
                            <small>Choose one, take in by scanning the Barcode or by Article Code (use article, if there is no barcode)</small>
                        </Col>
                        <Col lg={4} className="mb-3">
                            <Form onSubmit={handleChangeScanValue}>
                                <Form.Field>
                                    <label>Scan Barcode</label>
                                    <Input
                                        fluid={false}
                                        iconPosition="left"
                                        type="text"
                                        value={scanValue}
                                        onChange={(e) => setScanValue(e.target.value)}
                                        placeholder="EAN/UPC Barcode"
                                    >
                                        <Icon name="barcode" />
                                        <input />
                                    </Input>
                                </Form.Field>
                            </Form>
                        </Col>
                        <Col lg={4} sm={6}>
                            <Form>
                                <Form.Field>
                                    <label>Article</label>
                                    <Input
                                        fluid
                                        type="text"
                                        placeholder="Article"
                                        value={article}
                                        onChange={(e) => setArticle(e.target.value)}
                                        action={{ content: 'Lookup', onClick: handleExecuteArt, color: 'blue' }}
                                    />
                                </Form.Field>
                            </Form>
                        </Col>
                        <Col lg={9} sm={12} className="mb-3">
                            <Reacttable
                                columns={columnsSo}
                                data={selectedSize}
                                pageRow={5}
                                noData={selectedSize.length === 0 ? true : false}
                            />
                        </Col>
                        <Col lg={3}>
                            <div className="fw-bold">Total SO Qty :</div>
                            <div
                                style={{
                                    border: '2px solid lightgrey',
                                    borderRadius: '5px',
                                    height: '180px',
                                    width: '100%',
                                    background: 'whitesmoke',
                                    display: 'flex',
                                    justifyContent: 'center',  // Untuk horizontal center
                                    alignItems: 'center'       // Untuk vertical center
                                }}
                            >
                                <Transition visible={visible} animation='jiggle' duration={500}>
                                    <div className="display-1" style={{ fontFamily: 'fantasy', color: 'burlywood' }}>{curencyNoSym.format(totalQty)}</div>
                                </Transition>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button content="Update SO Entry" color="blue" icon="plus" type="submit" form="frmEdit" loading={loadEdit} disabled={loadEdit} />
                </Modal.Footer>
            </Modal>

            <Modal open={modalTimeline} onClose={() => setModalTimeline(false)} overflow={true} size='xs'>
                <Modal.Header>
                    <Modal.Title>
                        <div>Stock Movement Timeline</div>
                        <small>{moment(selectedRowSo?.header.createAt).format('YYYY-MM-DD')} to {moment().format('YYYY-MM-DD')}</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '0.9em' }}>
                    <Dimmer active={loadTimeline} inverted>
                        <Loader content='Loading...' />
                    </Dimmer>
                    <Timeline isItemActive={Timeline.ACTIVE_FIRST} endless>
                        {
                            stockTimeline.length > 0 ?
                                stockTimeline.map((item, i) => (
                                    <Timeline.Item key={i}>
                                        <div className="fw-bold">
                                            <span className="me-2">{item.move_time}</span>
                                            <span><Icon name={item.move_type === 'IN' ? 'arrow alternate circle down outline' : 'arrow alternate circle up outline'} color={item.move_type === 'IN' ? 'blue' : 'pink'} />{item.move_type}</span>
                                        </div>
                                        <div>
                                            <span className="me-3">{item.event}</span>
                                            <span>Qty : <span className="fw-bold">{item.qty}</span> &nbsp; Qty Before : {item.qty_before}</span>
                                        </div>
                                        <div className="text-muted">
                                            <span>User : </span>
                                            <span className="me-2">{item.user_name}</span>
                                            <span>({item.role_name})</span>
                                        </div>
                                    </Timeline.Item>
                                ))
                                :
                                <div className="text-center">
                                    <div><Icon name="info circle" color="orange" size="large" /></div>
                                    <div>No data in this moment</div>
                                </div>
                        }
                    </Timeline>
                </Modal.Body>
            </Modal>

        </>
    )
}