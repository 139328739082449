import React, { useEffect, useMemo, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { useMyGlobal } from "../component/Global";
import Tabs from 'rsuite/Tabs';
import moment from "moment";
import { BsBagCheck, BsBagX } from "react-icons/bs";
import DateRangePicker from "rsuite/DateRangePicker";
import { Button, ButtonGroup, Dropdown, Form, Popup } from "semantic-ui-react";
import { curencyNoSym } from "../component/utils";
import Badge from "rsuite/Badge";
import { Reacttable } from "../component/Reacttable";
import Modal from "rsuite/Modal"
import Table from "react-bootstrap/Table"
import { curency } from "../component/utils";
import { Toaster } from "../component/Toaster";

export const ReportSales = () => {
    const { host, userProfile, showroomOpt } = useMyGlobal()
    const [posTrx, setPosTrx] = useState([])
    const [posReturn, setPosReturn] = useState([])
    const [filteredShr, setFilteredShr] = useState([])
    const [totalCash, setTotalCash] = useState(0)
    const [totalCard, setTotalCard] = useState(0)
    const [showroom, setShowroom] = useState('')
    const [modalDetail, setModalDetail] = useState(false)
    const [modalPayDet, setModalPayDet] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectExport, setSelectExport] = useState('transaction')

    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])

    const columnsPosTrx = useMemo(() => [
        {
            Header: "Trx Code",
            accessor: "header.trx_code"
        },
        {
            Header: "Open ID",
            accessor: "header.id_open"
        },
        {
            Header: "Date",
            accessor: "header.trx_date"
        },
        {
            Header: "Time",
            accessor: "header.trx_time"
        },
        {
            Header: "Sales",
            accessor: "header.sales_name"
        },
        {
            Header: "Kasir",
            accessor: "header.user_name"
        },
        {
            Header: "Qty",
            accessor: "header.total_qty"
        },
        {
            Header: "Total Trx",
            accessor: "header.total_trx",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Voucher",
            accessor: "payment.total_voucher",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Payment",
            accessor: "payment.total_payment",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Type",
            accessor: "payment.payment_type",
            Cell: ({ cell }) => (
                <div>
                    <span className="me-2"><Badge color={cell.value === 'cash' ? 'green' : cell.value === 'card' ? 'blue' : 'yellow'} /></span>
                    <span className="text-capitalize">{cell.value}</span>
                </div>
            )
        },
        {
            Header : 'Bank',
            accessor: 'payment.bank'
        },
        {
            Header : 'Type',
            accessor: 'payment.card_type'
        },
        {
            Header: "Action",
            accessor: null,
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup size="tiny" content="View Detail" trigger={
                        <Button icon="list layout" onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                    <Popup size="tiny" content="Payment Detail" trigger={
                        <Button icon="money outline" onClick={() => handleClickPayDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article",
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '150px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    }/>
                </div>
            )
        },
        {
            Header: "Barcode",
            accessor: "barcode"
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty_order"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Disc",
            accessor: "additional_discount",
            Cell: ({ cell }) => cell.value !== "" ? `${cell.value}%` : ""
        },
        {
            Header: "Add Disc",
            accessor: "additional_discount_value",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Return",
            accessor: "is_return",
            Cell: ({ cell }) => (
                cell.value === "1" ?
                    <><span className="me-2"><Badge color="red" /></span><span>Returned</span></>
                    :
                    <></>
            )
        },
    ])

    const columnsRetur = useMemo(() => [
        {
            Header: "ID",
            accessor: "id_return",
        },
        {
            Header: "Code Trx",
            accessor: "code_trx",
        },
        {
            Header: "Date",
            accessor: "date_return",
        },
        {
            Header: "Article",
            accessor: "article",
        },
        {
            Header: "Size",
            accessor: "size",
        },
        {
            Header: "Barcode",
            accessor: "barcode",
        },
        {
            Header: "AdDsc",    
            accessor: "add_dsc",
            Cell:({cell}) => curencyNoSym.format(cell.value)
        },
        {
            Header: "Reason",
            accessor: "description",
        },
    ])


    const handleClickDetail = (data) => {
        setSelectedRow(data)
        setModalDetail(true)
    }

    const handleClickPayDetail = (data) => {
        setSelectedRow(data)
        setModalPayDet(true)
    }

    const getPosTrx = async (idShowroom) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: idShowroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                const cash = res.data.data.reduce((total, trx) => total + parseFloat(trx.payment.pay_cash), 0)
                const card = res.data.data.reduce((total, trx) => total + parseFloat(trx.payment.pay_card), 0)
                setPosTrx(res.data.data)
                setTotalCash(cash)
                setTotalCard(card)
                if (res.data.data.length === 0) {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'No Transaction Data Found',
                        position: 'top-right'
                    })
                }
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const getReturnData = async (idShowroom) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/pos/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'getReturnData',
                date_range: date_range,
                id_showroom: idShowroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setPosReturn(res.data.data)
                if (res.data.data.length === 0) {
                    Toaster.fire({
                        icon: 'warning',
                        text: 'No Return Data Found',
                        position: 'top-right'
                    })
                }
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const exportToExcel = (type, fileName) => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        const param = {
            showroom: showroom,
            date_range: date_range,
            type: type,
        }

        axios({
            method: "POST",
            url: `${host}/pos/index.php?action=exportSalesReport`,
            headers: {
                "Content-Type": "application/json",
            },
            data: param,
            responseType: 'blob'
        }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // Nama file yang akan diunduh
            document.body.appendChild(link);
            link.click();
            // Bersihkan URL Blob setelah file diunduh
            window.URL.revokeObjectURL(url);
        })
    }

    const handleSelectShr = (value) => {
        setShowroom(value)
    }

    const handleExc = () => {
        getPosTrx(showroom)
        getReturnData(showroom)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const handleExport = () => {
        if (showroom){
            exportToExcel(selectExport, selectExport === 'transaction' ? 'export_transaction.xlsx' : 'export_return.xlsx')
        } else {
            alert('Select showroom first...!')
        }
    }

    useEffect(() => {
        const filteredShr = showroomOpt.filter(opt =>
            userProfile.group.some(group => group.id_showroom === opt.value)
        )
        setFilteredShr(filteredShr)
    }, [userProfile])

    return (
        <>
            <Container>
                <div className="mb-3 mt-2">
                    <div className="h4">POS Sales Report</div>
                </div>
                <Row className="d-flex justify-content-center">
                    <Col xl={6} lg={8} md={12} sm={12} className="mb-3">
                        <DateRangePicker
                            className="mb-3"
                            label="Date Range : "
                            format="yyyy-MM-dd"
                            placement="bottomStart"
                            cleanable={true}
                            defaultValue={dateRange}
                            value={dateRange}
                            onChange={handleChangeDate}
                            onClean={() => setDateRange([
                                new Date(moment().startOf('month')),
                                new Date(moment()),
                            ])}
                        />
                        <Form onSubmit={handleExc}>
                            <Form.Group inline>
                                <Form.Dropdown
                                    placeholder="Select Showroom"
                                    options={filteredShr}
                                    selection
                                    value={showroom}
                                    clearable
                                    onChange={(e, { value }) => handleSelectShr(value)}
                                />
                                <Button content="Generate Data" color="blue" icon="arrow alternate circle right" labelPosition="right" type="submit" disabled={showroom === '' ? true : false} />
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col xl={6} lg={4} md={12} sm={12} className="mb-3 text-end">
                        {/* <Button icon={{name:'excel file', color:'white'}} color="blue" content="Export Detail" labelPosition="right" onClick={exportToExcel} disabled={showroom === '' ? true : false}/> */}
                        <ButtonGroup >
                            <Dropdown
                                disabled={showroom === ''}
                                labeled
                                className='button icon'
                                floating
                                trigger={<span className="text-capitalize">{selectExport}</span>}
                                options={[{ key: 0, text: 'Transaction', value: 'transaction', icon: { name: 'cart', color: 'black' } }, { key: 1, text: 'Return', value: 'return', icon: { name: 'undo', color: 'black' } }]}
                                onChange={(e, { value }) => setSelectExport(value)}
                            />
                            <Button onClick={handleExport} content="Export" icon="share" labelPosition="right" color="orange" disabled={showroom === ''}/>
                        </ButtonGroup>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <Tabs defaultActiveKey="1" appearance="tabs">
                            <Tabs.Tab eventKey="1" title="POS Transaction">
                                <Reacttable
                                    data={posTrx}
                                    columns={columnsPosTrx}
                                    pageRow={5}
                                    totals={['header.total_trx', 'payment.total_voucher', 'payment.total_payment', 'header.total_qty']}
                                    noData={posTrx.length === 0 ? true : false}
                                />
                            </Tabs.Tab>
                            <Tabs.Tab eventKey="2" title="POS Transaction Return" >
                                <Reacttable data={posReturn} columns={columnsRetur} pageRow={10} noData={posReturn.length === 0 ? true : false}/>
                            </Tabs.Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>

            <Modal open={modalDetail} onClose={() => setModalDetail(false)} overflow={false} size="md">
                <Modal.Header>
                    <Modal.Title>Detail Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable data={selectedRow?.detail} columns={columnsDetail} pageRow={10} noData={selectedRow?.detail.length === 0 ? true : false}/>
                </Modal.Body>
            </Modal>

            <Modal open={modalPayDet} onClose={() => setModalPayDet(false)} overflow={false} size="xs">
                <Modal.Header>
                    <Modal.Title>Payment Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12}>
                            <div style={{ border: 'solid 1px lightgrey', borderRadius: '5px', padding: '10px' }}>
                                <Table style={{ fontSize: '0.9em' }} className="table">
                                    <tbody >
                                        <tr>
                                            <td>Type</td>
                                            <td className="text-end">:</td>
                                            <td className="text-capitalize text-end fw-bold">
                                                <span className="me-2"><Badge color={selectedRow?.payment?.payment_type === 'cash' ? 'green' : selectedRow?.payment?.payment_type === 'card' ? 'blue' : 'yellow'} /></span>
                                                <span>{selectedRow?.payment?.payment_type}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Transaction Total</td>
                                            <td className="text-end">:</td>
                                            <td className="text-capitalize text-end fw-bold">{curency.format(selectedRow?.payment?.grand_total)}</td>
                                        </tr>
                                        <tr>
                                            <td>Voucher</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.total_voucher)}</td>
                                        </tr>
                                        <tr>
                                            <td>Return Deposit</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.deposit ? curency.format(selectedRow?.payment?.deposit) : 'Rp 0'}</td>
                                        </tr>
                                        <tr>
                                            <td>Card Number</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.last_digit}</td>
                                        </tr>
                                        <tr>
                                            <td>Trace Number</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.ref_number}</td>
                                        </tr>
                                        <tr>
                                            <td>Paid</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{selectedRow?.payment?.total_paid ? curency.format(selectedRow?.payment?.total_paid) : 'Rp 0'}</td>
                                        </tr>
                                        <tr>
                                            <td>Change</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.change)}</td>
                                        </tr>
                                        <tr>
                                            <td>Cash</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.pay_cash)}</td>
                                        </tr>
                                        <tr>
                                            <td>Card</td>
                                            <td className="text-end">:</td>
                                            <td className="text-end fw-bold">{curency.format(selectedRow?.payment?.pay_card)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}