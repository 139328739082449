import React, { useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import DateRangePicker from "rsuite/DateRangePicker";
import moment from "moment";
import { Button, Dropdown, Popup } from "semantic-ui-react";
import { useMyGlobal } from "../component/Global";
import Stack from "react-bootstrap/Stack";
import { Toaster } from "../component/Toaster";
import axios from "axios";
import { curency, curencyNoSym } from "../component/utils";
import Card from "react-bootstrap/Card"
import Modal from "rsuite/Modal"
import { Reacttable } from "../component/Reacttable";


export const Dashboard = () => {
    const [dateRange, setDateRange] = useState([
        new Date(moment().startOf('month')),
        new Date(moment()),
    ])
    const { host, showroomOpt, playSound } = useMyGlobal()
    const [salesData, setSalesData] = useState([])
    const [salesSeriesData, setSalesSeriesData] = useState([])
    const [showroom, setShowroom] = useState('')
    const [chartSalesOpt, setChartSalesOpt] = useState({})
    const [bestSeler, setBestSeler] = useState([])
    const [salesPerMonth, setSalesPerMonth] = useState([])
    const [salesPerBrand, setSalesPerBrand] = useState([])
    const [trxByDate, setTrxByDate] = useState([])
    const [modalTrx, setModalTrx] = useState(false)
    const [modalTrxDetail, setModalTrxDetail] = useState(false)
    const [selectedTrxDetail, setSelectedTrxDetail] = useState(null)
    const [salesDatePercentage, setSalesDatePercentage] = useState([])
    const [salesPerBrandGroup, setSalesPerBrandGroup] = useState([])
    const [salesPerTarget, setSalesPerTarget] = useState([])

    const monthNames = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    const columnsTrxData = useMemo(() => [
        {
            Header: "Trx Number",
            accessor: "header.trx_code"
        },
        {
            Header: "Date",
            accessor: "header.trx_date"
        },
        {
            Header: "Time",
            accessor: "header.trx_time"
        },
        {
            Header: "Cashier",
            accessor: "header.user_name"
        },
        {
            Header: "Sales",
            accessor: "header.sales_name"
        },
        {
            Header: "Qty",
            accessor: "header.total_qty"
        },
        {
            Header: "Total",
            accessor: "header.total_payment"
        },
        {
            Header: "Action",
            accessor: "",
            Cell: ({ cell }) => (
                <Button.Group size="mini" basic>
                    <Popup content="Transaction Detail" size="tiny" trigger={
                        <Button icon={{ name: 'list layout', color: 'blue' }} onClick={() => handleClickDetail(cell.row.original)} />
                    } />
                </Button.Group>
            )
        },
    ])

    const columnsTrxDataDetail = useMemo(() => [
        {
            Header: "Article",
            accessor: "article"
        },
        {
            Header: "Description",
            accessor: "description",
            Cell: ({ cell }) => (
                <div style={{ width: '120px' }}>
                    <Popup content={cell.value} size="tiny" trigger={
                        <div className="text-truncate">{cell.value}</div>
                    } />
                </div>
            )
        },
        {
            Header: "Size",
            accessor: "size"
        },
        {
            Header: "Qty",
            accessor: "qty_order"
        },
        {
            Header: "Price",
            accessor: "price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Add Discount",
            accessor: "additional_discount_value",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Final Price",
            accessor: "final_price",
            Cell: ({ cell }) => (curencyNoSym.format(cell.value))
        },
        {
            Header: "Total",
            accessor: "",
            Cell: ({ cell }) => (curencyNoSym.format(cell.row.original.final_price * cell.row.original.qty_order))
        },
    ])

    const handleClickDetail = (data) => {
        setSelectedTrxDetail(data)
        setModalTrxDetail(true)
        console.log(data)
    }

    const handleChangeDate = (e) => {
        setDateRange(e)
    }

    const getSalesData = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesData(res.data.data)
                console.log(res.data.data)
                // setSalesSeriesData(
                //     Object.entries(res.data.data.reduce((acc, sale) => {
                //         const { trx_date, total_trx } = sale.header;
                //         if (!acc[trx_date]) {
                //             acc[trx_date] = 0;
                //         }
                //         acc[trx_date] += parseFloat(total_trx);
                //         return acc;
                //     }, {}))
                //         .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
                //         .map(([date, totalTrx]) => ({ date, totalTrx }))
                // )

                const groupedData = res.data.data.reduce((acc, sale) => {
                    const { trx_date, total_trx, sales_type } = sale.header;

                    if (!acc[trx_date]) {
                        acc[trx_date] = {};
                    }

                    if (!acc[trx_date][sales_type]) {
                        acc[trx_date][sales_type] = 0;
                    }

                    acc[trx_date][sales_type] += parseFloat(total_trx);
                    return acc;
                }, {});

                // Format data untuk ApexCharts
                const dates = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b));
                const salesTypes = Array.from(new Set(res.data.data.map(sale => sale.header.sales_type)));

                const series = salesTypes
                    .sort((a, b) => a === 'offstore' ? -1 : b === 'offstore' ? 1 : 0) // Menempatkan 'offstore' di posisi pertama
                    .map(type => ({
                        name: type,
                        data: dates.map(date => groupedData[date][type] || 0),
                    }));

                // Hitung total untuk setiap tanggal, untuk persentase.
                setSalesDatePercentage(dates.map((date, index) =>
                    series.reduce((acc, series) => acc + series.data[index], 0)
                ))

                setSalesSeriesData(series)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        })
    }

    const bestSelerItem = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'bestSellerItem',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setBestSeler(res.data.data)
            }
        })
    }

    const salesPerMonthData = async () => {

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerMonth',
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerMonth(res.data.data)
            }
        })
    }

    const getSalesPerBrand = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerBrand',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerBrand(res.data.data)
            }
        })
    }

    const getSalesPerBrandGroup = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerBrandGroup',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerBrandGroup(res.data.data)
            }
        })
    }

    const getSalesPerTarget = async () => {
        const formatDate = (date) => moment(date).format('YYYY-MM-DD')
        const [startDate, endDate] = dateRange.map(date => formatDate(date))
        const date_range = [startDate, endDate]

        await axios({
            method: "GET",
            url: `${host}/dashboard/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'salesPerTarget',
                id_showroom: showroom,
                date_range: date_range,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setSalesPerTarget(res.data.data)
            }
        })
    }

    const handleSelectShowroom = (value) => {
        setShowroom(value)
    }

    const handleGenerate = () => {
        playSound('show')
        getSalesData()
        bestSelerItem()
        salesPerMonthData()
        getSalesPerBrand()
        getSalesPerBrandGroup()
        getSalesPerTarget()
    }

    const getTransactionByDate = async (date) => {
        const date_range = [date, date]

        await axios({
            method: "GET",
            url: `${host}/sales/index.php`,
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                action: 'posTransactionByShowroom',
                date_range: date_range,
                id_showroom: showroom,
            }
        }).then(res => {
            if (res.data.status === 200) {
                setTrxByDate(res.data.data)
            } else {
                Toaster.fire({
                    icon: 'error',
                    text: res.data.msg,
                    position: 'top-right'
                })
            }
        }).finally(() => {
            setModalTrx(true)
        })

    }
   
    return (
        <>
            <Container fluid>
                <div className="mb-3 mt-2">
                    <div className="h4">Dashboard</div>
                </div>
                <Row className="d-flex justify-content-left mb-4">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Stack direction="horizontal" gap={3}>
                            <Dropdown
                                placeholder="Select Showroom"
                                selection
                                options={showroomOpt}
                                clearable
                                onChange={(e, { value }) => handleSelectShowroom(value)}
                            />
                            <DateRangePicker
                                disabled={showroom !== '' ? false : true}
                                label="Date Range : "
                                format="yyyy-MM-dd"
                                placement="bottomStart"
                                cleanable={true}
                                defaultValue={dateRange}
                                value={dateRange}
                                onChange={handleChangeDate}
                                onClean={() => setDateRange([
                                    new Date(moment().startOf('month')),
                                    new Date(moment()),
                                ])}
                            />
                            <Button content="Generate Data" color="blue" icon="magic" onClick={handleGenerate} disabled={showroom !== '' ? false : true} />
                        </Stack>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Sales data per date</Card.Header>
                            <Card.Body>
                                {
                                    salesData ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    chart: {
                                                        type: "bar",
                                                        events: {
                                                            dataPointSelection: (event, chartContext, config) => {
                                                                const { dataPointIndex } = config
                                                                const dates = salesData
                                                                    .map(sale => sale.header.trx_date)
                                                                    .reduce((acc, date) => {
                                                                        if (!acc.includes(date)) acc.push(date);
                                                                        return acc;
                                                                    }, [])
                                                                    .sort((a, b) => new Date(a) - new Date(b));
                                                                getTransactionByDate(dates[dataPointIndex])
                                                            }
                                                        },
                                                        stacked: true
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: salesData
                                                            .map(sale => sale.header.trx_date)
                                                            .reduce((acc, date) => {
                                                                if (!acc.includes(date)) {
                                                                    acc.push(date);
                                                                }
                                                                return acc;
                                                            }, [])
                                                            .sort((a, b) => new Date(a) - new Date(b))
                                                            .map(date => new Date(date).toLocaleDateString('in-US', {
                                                                weekday: 'long',
                                                                month: 'short',
                                                                day: 'numeric'
                                                            }))
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return curency.format(val);
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: true,
                                                        formatter: function (val, { dataPointIndex, seriesIndex }) {
                                                            const total = salesDatePercentage[dataPointIndex];
                                                            const percentage = (val / total) * 100;
                                                            return `${percentage.toFixed(1)}%`; // Format ke persentase 1 desimal
                                                        },
                                                    },
                                                }
                                            }
                                            series={salesSeriesData}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                            <Card.Footer>
                                <div><span className="text-muted">Total Sales</span> : <span>{curency.format(salesData.reduce((total, cur) => total + parseFloat(cur.header.total_trx), 0))}</span></div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Sales data per Month</Card.Header>
                            <Card.Body>
                                {
                                    salesPerMonth ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                            dataLabels: {
                                                                position: 'top', // top, center, bottom
                                                            },
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: salesPerMonth.map(item => { return `${monthNames[item.month - 1]} ${item.year}` })
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return curency.format(val);
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: true,
                                                        textAnchor: "middle",
                                                        offsetY: -20,
                                                        style: {
                                                            fontSize: '9px',
                                                            colors: ['#333'],
                                                            fontWeight: 'bold',
                                                        },
                                                        formatter: function (val) {
                                                            return curencyNoSym.format(val);
                                                        },
                                                    },
                                                    tooltip: {
                                                        shared: false,
                                                        custom: function({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(series[seriesIndex][dataPointIndex]);
                                                            const qty = salesPerMonth[dataPointIndex].qty;  // assuming qty is in second series
                                            
                                                            return (
                                                                `<div class="apexcharts-tooltip-title">
                                                                    <span>${w.config.xaxis.categories[dataPointIndex]}</span>
                                                                </div>
                                                                <div class="apexcharts-tooltip-items p-2">
                                                                    <div>
                                                                        <strong>Amount: </strong> ${amount}
                                                                    </div>
                                                                    <div>
                                                                        <strong>Qty: </strong> ${qty} Sold
                                                                    </div>
                                                                </div>`
                                                            );
                                                        },
                                                    },
                                                }
                                            }
                                            series={[
                                                {
                                                    name: 'Amount',
                                                    data: salesPerMonth.map(item => item.amount)
                                                }
                                        ]}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl={6} lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Top 10 Best seller products</Card.Header>
                            <Card.Body>
                                {
                                    bestSeler ?
                                        <ReactApexChart
                                            type="bar"
                                            options={
                                                {
                                                    plotOptions: {
                                                        bar: {
                                                            borderRadius: 0,
                                                            horizontal: false,
                                                        },
                                                    },
                                                    xaxis: {
                                                        categories: bestSeler.map(item => item.article)
                                                    },
                                                    yaxis: {
                                                        labels: {
                                                            formatter: function (val) {
                                                                return val + ' Items';
                                                            },
                                                        },
                                                    },
                                                    dataLabels: {
                                                        enabled: false,
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const qty = series[seriesIndex][dataPointIndex]
                                                            const desc = bestSeler[dataPointIndex].description
                                                            const price = curency.format(bestSeler[dataPointIndex].price)
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                    <span>${desc}</span><br/>
                                                                    <span>Price : ${price}</span><br/>
                                                                    <span style="font-weight:bold">Sold : ${qty} </span><br/>
                                                                   
                                                                </div>`
                                                        }
                                                    }
                                                }
                                            }
                                            series={[{
                                                data: bestSeler.map(item => item.sale)
                                            }]}
                                            height={350}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Sales Per Brand</Card.Header>
                            <Card.Body>
                                {
                                    salesPerBrand ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerBrand.map(item => item.description),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 0,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerBrand[seriesIndex].total_final_price)
                                                            const qty = salesPerBrand[seriesIndex].total_qty
                                                            const brand = salesPerBrand[seriesIndex].description
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Brand : ${brand} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerBrand.map(item => parseInt(item.total_final_price))}
                                            height={250}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Brand Group (Category)</Card.Header>
                            <Card.Body>
                                {
                                    salesPerBrandGroup ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerBrandGroup.map(item => item.brand_sub_group),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 0,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerBrandGroup[seriesIndex].transaction)
                                                            const qty = salesPerBrandGroup[seriesIndex].total_qty
                                                            const cat = salesPerBrandGroup[seriesIndex].brand_sub_group
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Category : ${cat} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerBrandGroup.map(item => parseInt(item.total_qty))}
                                            height={250}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                    <Col xl={4} lg={6} md={12} sm={12} xs={12} className="mb-4">
                        <Card>
                            <Card.Header className="text-capitalize">Sales Per Target</Card.Header>
                            <Card.Body>
                                {
                                    salesPerTarget ?
                                        <ReactApexChart
                                            type="donut"
                                            options={
                                                {
                                                    chart: {
                                                        redrawOnParentResize: true,
                                                        redrawOnWindowResize: true
                                                    },
                                                    labels: salesPerTarget.map(item => item.target),
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    responsive: [{
                                                        breakpoint: 480,
                                                        options: {
                                                            chart: {
                                                                width: '100%',
                                                            },
                                                            legend: {
                                                                show: true,
                                                            }
                                                        }
                                                    }],
                                                    legend: {
                                                        position: 'right',
                                                        offsetY: 0,
                                                        height: '100%',
                                                        width: 150
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            startAngle: 0,
                                                            endAngle: 360,
                                                            expandOnClick: true,
                                                            offsetX: 0,
                                                            offsetY: 0,
                                                            customScale: 1,
                                                            dataLabels: {
                                                                offset: 0,
                                                                minAngleToShowLabel: 10
                                                            },
                                                            donut: {
                                                                size: "50%",
                                                            }
                                                        }
                                                    },
                                                    tooltip: {
                                                        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                                            const amount = curency.format(salesPerTarget[seriesIndex].transaction)
                                                            const qty = salesPerTarget[seriesIndex].total_qty
                                                            const target = salesPerTarget[seriesIndex].target
                                                            return `<div style="padding: 10px; font-size:0.9em">
                                                                                <span>Target : ${target} </span><br/>
                                                                                <span>Amount : ${amount}</span><br/>
                                                                                <span>Qty : ${qty} Items</span>
                                                                            </div>`
                                                        }
                                                    },
                                                }
                                            }
                                            series={salesPerTarget.map(item => parseInt(item.total_qty))}
                                            height={250}
                                        />
                                        : <>Loading</>
                                }
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
            </Container>

            <Modal open={modalTrx} onClose={() => setModalTrx(false)} overflow={false} backdrop="static" children={false} size="md">
                <Modal.Header>
                    <Modal.Title>POS Transaction Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable columns={columnsTrxData} data={trxByDate} pageRow={10} />
                </Modal.Body>
            </Modal>

            <Modal open={modalTrxDetail} onClose={() => setModalTrxDetail(false)} overflow={false} backdrop="static" children={true} size="md">
                <Modal.Header>
                    <Modal.Title>POS Transaction Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Reacttable columns={columnsTrxDataDetail} data={selectedTrxDetail?.detail} pageRow={10} />
                </Modal.Body>
            </Modal>
        </>
    )
}